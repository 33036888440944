import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "../../authContext";
import axios from 'axios';

export default function NewBot() {
  const { user: loggedUser } = useAuthState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const isAuth = localStorage.getItem('access_token');
  if (!isAuth) {
    return navigate("/");
  }

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      };
      const response = await axios.post('https://hirebot.co/api/v1/chatbots', {
        name,
        data_source: {
          data: description
        }
      }, { headers });

      if (response.status === 200) {
        const user_data = response.data;
        navigate('/app'); 
      } else {
        console.error('Create failed');
      }
    } catch (error) {
      console.error('Create failed', error);
    }
  };

  return (
    <>
       <div className="flex h-screen bg-gray-100">
      <div className="w-3/4 mx-auto pt-4">
        <div className="bg-white p-6">
          <h2 class="mb-4 text-xl font-bold text-gray-900 dark:text-white">Add a new bot</h2>
          <form onSubmit={handleCreate}>
            <div class="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div class="sm:col-span-2">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Job Name</label>
                <input type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                 placeholder="Type job name" required="" onChange={(e) => setName(e.target.value)} />
              </div>
              <div class="sm:col-span-2">
                <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                <textarea id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                 placeholder="Type description here"  onChange={(e) => setDescription(e.target.value)} ></textarea>
              </div>
            </div>
            <button type="submit" class="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
              Save
            </button>
          </form>
        </div>
        </div>
      </div></>
  );
}
