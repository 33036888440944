import React, { useEffect } from 'react';

import { Outlet } from "react-router-dom";
import AppHeaderNav from '../components/appHeader'
import { useAuthDispatch, fetchUserData } from "../authContext";


const AppLayout = () => {
    const dispatch = useAuthDispatch();

    useEffect(() => {
      // Call the fetchUserData function when the component mounts
      fetchUserData(dispatch);
    }, [dispatch]);

    function isDarkMode() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return true;
        }
        return false;
      }
      
      // Function to update favicon based on the dark mode
      function updateFavicon() {
        const faviconElement = document.getElementById('favicon');
        const faviconPath = isDarkMode() ? 'https://storage.googleapis.com/hirebot-storage/static/fav-dark.png' : 'https://equations.fra1.cdn.digitaloceanspaces.com/resume/dark.png';
        faviconElement.setAttribute('href', faviconPath);
      }
      
      // Call the function when the page loads
      updateFavicon();
      
      // You can also listen for changes in the color scheme and update the favicon accordingly
      window.matchMedia('(prefers-color-scheme: dark)').addListener(updateFavicon);

  return (
    <>
      <AppHeaderNav/>
      <div className='bg-gray-100'>
        <Outlet />
        </div>
        <footer class="bg-white rounded-lg shadow sm:flex sm:items-center sm:justify-between p-4 sm:p-6 xl:p-8 dark:bg-gray-800">
  <p class="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
      &copy; 2023 <a href="/" class="hover:underline" target="_blank">Hirebot.co</a>. All rights reserved.
  </p>
  
</footer>
    </>
  )
};

export default AppLayout;
