import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthState, useAuthDispatch } from "../authContext";



export default function SignUp() {
  const dispatch = useAuthDispatch();
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const isAuth = localStorage.getItem('access_token');
  if (isAuth) {
   return navigate("/app")
  }
  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://hirebot.co/api/v1/auth/register', {
        name,
        lastname,
        email,
        password,
      });

      if (response.status === 200) {
        const user_data = response.data;
        navigate('/login'); // Redirect to login page
      } else {
        console.error('Signup failed');
      }
    } catch (error) {
      console.error('Signup failed', error);
    }
  };
  return (
    <section class="bg-white dark:bg-gray-900">
    <div class="flex flex-col items-center mt-20 px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Create new account
              </h1>
              </div>
          <form class="login-form px-10 pb-6" onSubmit={handleSignUp}>
            <div class="relative mb-6" data-te-input-wrapper-init>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                Your Name:
              </label>
              <input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="John"
                onChange={(e) => setName(e.target.value)} />

            </div>

            <div class="relative mb-6" data-te-input-wrapper-init>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="surname">
                Your lastname:
              </label>
              <input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="surname" type="text" placeholder="Doe"
                onChange={(e) => setLastname(e.target.value)} />

            </div>

            <div class="relative mb-6" data-te-input-wrapper-init>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
                Email:
              </label>
              <input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="johndoe@email.com"
                onChange={(e) => setEmail(e.target.value)} />

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                Password:
              </label>
              <input class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="password" placeholder="Password"
                onChange={(e) => setPassword(e.target.value)} />

            </div>

            <button
              type="submit"
              class="inline-block w-full rounded bg-blue-500 text-white px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              data-te-ripple-init
              data-te-ripple-color="light">
              Sign up
            </button>

            <div
              class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p
                class="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                OR
              </p>
            </div>

            <a
              class="mb-3 flex w-full items-center justify-center rounded bg-primary px-7 pb-2.5 pt-3 text-center text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              style={{ backgroundColor: '#3b5998' }}
              href="/login"
              role="button"
              data-te-ripple-init
              data-te-ripple-color="light">
              Sign In
            </a>

          </form>
        </div>
      </div>
    </section>
  )
}