import React from "react";
import axios from "axios";
import { appHistory } from "./history";

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error("useAuthState must be used in AuthProvider");

  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context) throw new Error("useAuthDispatch must be used in AuthProvider");

  return context;
}

const initialState = {
  status: "idle",
  user: null,
  error: null
};

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

async function doLogin(dispatch, email, password) {
  try {
    dispatch({ status: "pending" });
    const response = await axios.post('https://hirebot.co/api/v1/auth/login', {
      email,
      password,
    });

    if (response.status === 200) {
      const token = response.data;
      console.log(token);
      // Save the token in local storage
      localStorage.setItem('access_token', token.access_token);
      localStorage.setItem('refresh_token', token.refresh_token);

      // Fetch and update user data after successful login
      await fetchUserData(dispatch);

      // Redirect to dashboard or any other desired route
      appHistory.push("/app");
    } else {
      console.error('Login failed');
    }
  } catch (error) {
    dispatch({ status: "rejected", error });
  }
}

async function fetchUserData(dispatch) {
  try {
    const response = await axios.get('https://hirebot.co/api/v1/auth/user', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });

    if (response.status === 200) {
      const userData = response.data;
      dispatch({ status: "resolved", user: userData, error: null });
    } else if (response.status === 401) {
      // Token expired or invalid
      doLogout(dispatch);
    } else {
      console.error('Fetch user data failed');
    }
  } catch (error) {
    dispatch({ status: "rejected", error });
  }
}

function doLogout(dispatch) {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  dispatch(initialState);
  console.log("logout");
}

export { AuthProvider, useAuthState, useAuthDispatch, doLogin, doLogout, fetchUserData };
