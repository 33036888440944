const generateTailwindList = (data) => {
    const keys = Object.keys(data);

    const listItems = keys
        .filter((key) => key !== 'overall_match_score' && key !== 'candidate_full_name' && key !== 'overall_match_description' && key !== 'overall_read_time')
        .map((key) => {
            const capitalizedKey = key.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
            const matchColor = data[key].match ? 'text-green-600' : 'text-red-600';
            return (
                <li key={key} className="py-2 ">
                    <span className="font-semibold">{capitalizedKey}:</span>
                    <span className={`ml-1 ${matchColor}`}>{`${data[key].match}`}</span>
                    <span className="text-sm text-gray-600">[Score: {data[key].score}]</span>
                    <p className="text-gray-600">{data[key].description}</p>
                </li>
            );
        });
    const overallMatchScore = data['overall_match_score'];
    const overallMatchDescription = data['overall_match_description'];
    const overallReadTime = data['overall_read_time'];
    const candidateFullName = data['candidate_full_name'];

    return (
        <div className="p-2">
            <p className="mb-4 font-semibold bg-white p-2 rounded">Candidate: {candidateFullName}</p>
            <ul className="space-y-2 bg-white p-2 rounded">{listItems}</ul>
            <p className="mt-4 font-semibold bg-white p-2 rounded">Resume read time: {overallReadTime}</p>
            <div className='mt-4 bg-white p-2 rounded'>
                <p className="font-semibold ">Overall Match Score: {overallMatchScore}</p>
                <p className="text-gray-6">{overallMatchDescription}</p>
            </div>

        </div>
    );
};

export default generateTailwindList