import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState, useAuthDispatch, fetchUserData } from "../authContext";
import Dropdown from '../components/dropDown';



export default function HeaderNav() {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAuthDispatch();
    const { user: loggedUser, status, error } = useAuthState();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    
    const isAuth = localStorage.getItem('access_token');

    return (
      <header className="sticky top-0 bg-white dark:bg-gray-900 py-3 px-6 z-50">
        <nav className="container mx-auto flex items-center justify-between">
          <a href="/" className="text-xl font-bold text-gray-800"><img src="https://storage.googleapis.com/hirebot-storage/static/logo.png" style={{height: 40}} alt="HireBot Logo"/></a>
          <div className="lg:hidden">
            <button
              className="text-gray-500 hover:text-gray-600 focus:text-gray-600 focus:outline-none dark:text-gray-200 dark:hover:text-gray-400 dark:focus:text-gray-400"
              onClick={toggleMenu}
              type="button"
              aria-label="toggle menu"
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 8h16M4 16h16"
                  />
                </svg>
              )}
            </button>
          </div>  
          <ul className={`hidden lg:flex space-x-6 items-center justify-between ${isOpen ? 'block' : 'hidden'}`}>
            <li><a href="/" className="text-gray-600 hover:text-gray-800 nav-link">Home</a></li>
            <li><a href="/#features" className="text-gray-600 hover:text-gray-800 nav-link">Features</a></li>
            <li><a href="/#pricing" className="text-gray-600 hover:text-gray-800 nav-link">Pricing</a></li>
          </ul>
          {
            !isAuth ? (
          <Link to='/login'
            type="submit"
            class="inline-flex justify-center items-center py-2 px-8 text-base hidden lg:block font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            data-te-ripple-init
            data-te-ripple-color="light">
            Login
          </Link>
            ) : (
              <Dropdown/>
            )
          }
          
        </nav>
        {/* Mobile Menu */}
        <ul className={`lg:hidden mobile_menu mt-4 space-y-2 ${isOpen ? 'block' : 'hidden'}`}>
          <li><a href="/" className="block text-gray-600 hover:text-gray-800">Home</a></li>
          <li><a href="/#features" className="block text-gray-600 hover:text-gray-800">Features</a></li>
          <li><a href="/#pricing" className="block text-gray-600 hover:text-gray-800">Pricing</a></li>
          {isAuth ? (
							<li><a href="/app" className="block text-gray-600 hover:text-gray-800">Dashboard</a></li>
						) : (
							<li><a href="/login" className="block text-gray-600 hover:text-gray-800">Login</a></li>
						)}

        </ul>
      </header>
    )
}