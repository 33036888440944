import React, { useState, useRef } from 'react';

const DragDropFileInput = ({ onFileChange }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const inputFileRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileChange(file);
    }
  };

  const handleInputClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange(e);
      e.target.value = '';
    }
  };

  return (
    <div
      className={`w-full h-12 cursor-pointer border-dashed border-2 ${
        isDragOver ? 'border-primary-500' : 'border-neutral-300'
      } flex justify-center items-center`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleInputClick}
    >
      <input
        type="file"
        className="hidden"
        ref={inputFileRef}
        onChange={handleFileInputChange}
      />
      <p className="text-gray-700 cursor-pointer">Drag and drop a pdf resume here or click to browse</p>
    </div>
  );
};

export default DragDropFileInput;
