import React, { useState } from 'react';

export default function ComingSoon() {
  return (
    <div className="video-background">
      <video autoPlay loop muted>
        <source src="27706-365890968_small.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>
    </div>
  );
}