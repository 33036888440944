import React, { useState } from 'react';
import { useAuthState, useAuthDispatch, doLogout } from "../authContext";
import Avatar from "react-avatar";


function Dropdown() {
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const dispatch = useAuthDispatch();
    const { user: loggedUser, status } = useAuthState();
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="relative hidden lg:block mr-1">
            <div onClick={toggleDropdown} className="relative z-10 block rounded-md bg-white p-2 focus:outline-none cursor-pointer">
            
            {status === "resolved" && (
          <Avatar
            name={`${loggedUser.name} ${loggedUser.lastname}`} // Concatenate name and lastname
            round={true}
            size="30"
            textSizeRatio={1}
          />
        )}
            </div>

            {!dropdownOpen && <div onClick={toggleDropdown} className="fixed inset-0 h-full w-full z-10"></div>}

            {!dropdownOpen && (
                <div className="absolute right-0 mt-2 p-2  w-48 bg-white dark:bg-gray-900 rounded-md shadow-xl z-20">
                    <a href="/app" className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-800 hover:text-white">
                        dashboard
                    </a>

                    <li onClick={() => { doLogout(dispatch) }} className="block w-full cursor-pointer px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-800 hover:text-white">
                        Sign Out
                    </li>
                </div>
            )}
        </div>
    );
}

export default Dropdown;
