import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useAuthState } from "../../authContext";
import ChatHistory from "../../components/app/chatHistory";
import Loading from "../../components/loading";
import ChatBox from "../../components/app/chatBox";


const ChatbotDetail = () => {
  const { user: loggedUser } = useAuthState();
  const { slug } = useParams(); // Get the slug from URL parameter
  const [chatbot, setChatbot] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('chat'); // State to track active tab


  useEffect(() => {
    // Fetch chatbot details using the slug
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };
    axios.get(`https://hirebot.co/api/v1/chatbots/${slug}`, { headers })
      .then(response => {
        setChatbot(response.data);
      })
      .catch(error => {
        console.error("Error fetching chatbot details:", error);
      });
  }, [slug]);

  const isAuth = localStorage.getItem('access_token');
  if (!isAuth) {
    return navigate("/");
  }

  if (!chatbot) {
    return <Loading />

  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
       <div className="flex h-screen bg-gray-100">
      <div className="w-3/4 mx-auto pt-4">
        <div className="bg-white">
          <div class="border-b border-gray-200 dark:border-gray-700 ">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              <li class="mr-2">
                <a
                  href="javascript:void(0);"
                  onClick={() => handleTabChange('chat')} // Change tab on click
                  class={`inline-flex items-center justify-center p-4 ${activeTab === 'chat'
                      ? 'text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group'
                      : 'border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
                    }`}
                  aria-current={activeTab === 'measure' ? 'page' : undefined}
                >
                  <svg
                    class={`w-4 h-4 mr-2 ${activeTab === 'chat'
                        ? 'text-blue-600 dark:text-blue-500'
                        : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'
                      }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 18"
                  >
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />

                  </svg>
                  Chat
                </a>
              </li>
              <li class="mr-2">
                <a
                  href="javascript:void(0);"
                  onClick={() => handleTabChange('history')} // Change tab on click
                  class={`inline-flex items-center justify-center p-4 ${activeTab === 'history'
                      ? 'text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group'
                      : 'border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'
                    }`}
                  aria-current={activeTab === 'history' ? 'page' : undefined}
                >
                  <svg
                    class={`w-4 h-4 mr-2 ${activeTab === 'history'
                        ? 'text-blue-600 dark:text-blue-500'
                        : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'
                      }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 18"
                  >
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                  </svg>
                  History
                </a>
              </li>
            </ul>
          </div>
          {activeTab === 'chat' ? <ChatBox chatbot={chatbot} /> : null}
          {activeTab === 'history' ? <ChatHistory /> : null}
        </div>
        </div>
      </div>
    </>
  );
};

export default ChatbotDetail;