import React from 'react';


export default function PrivacyPage() {


  return (
    <>
      <main className='bg-white'>
        <div class="flex justify-center items-center ">
         <div class=" mx-auto p-8">

        <h1 class="text-2xl font-bold mb-4">Privacy Policy for İş Elanları Mobile Application</h1>

        <p class="mb-4">Date: 10.07.2023</p>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">1. Information We Collect</h2>

            <div class="ml-6">
                <h3 class="text-lg font-bold mb-2">1.1 Personal Information</h3>
                <p>When you create an account, we may collect your name, email address, phone number, and other relevant contact information.
If you choose to upload a resume or other documents, we may collect information contained therein, including employment history, skills, education, and other relevant details.
We may collect additional information you choose to provide, such as your location, preferences, and settings.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">1.2 Device Information</h3>
                <p>We may collect information about the device you use to access the App, including device type, operating system, unique device identifiers, and mobile network information.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">1.3 Log Data</h3>
                <p>We automatically collect information about your use of the App, including the actions you take, the pages you visit, and the dates and times of your interactions.</p>
            </div>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">2. How We Use Your Information</h2>

            <div class="ml-6">
                <h3 class="text-lg font-bold mb-2">2.1 Providing Services</h3>
                <p>We use the information you provide to deliver our services, including facilitating job searches, matching candidates with job listings, and enabling communication between employers and candidates.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">2.2 Communications</h3>
                <p>We may send you notifications, alerts, and updates related to your account or the App's features.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">2.3 Improving the App</h3>
                <p>We use information about how you interact with the App to analyze and optimize our services, enhance user experience, and develop new features.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">2.4 Legal Compliance</h3>
                <p>We may use your information to comply with legal obligations, resolve disputes, and enforce our policies.</p>
            </div>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">3. Information Sharing</h2>

            <div class="ml-6">
                <h3 class="text-lg font-bold mb-2">3.1 With Employers and Candidates</h3>
                <p>our profile and relevant information may be shared with employers and candidates in order to facilitate the job matching process.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">3.2 Service Providers</h3>
                <p>We may share your information with trusted third-party service providers who assist us in operating, maintaining, and improving the App.</p>

                <h3 class="text-lg font-bold mb-2 mt-4">3.3 Legal Compliance and Safety</h3>
                <p>We may share your information to comply with applicable laws, regulations, or governmental requests, or to protect the rights, property, and safety of İş Elanları, our users, or others.</p>
            </div>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">4. Security</h2>
            <p>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet, or method of electronic storage, is 100% secure.</p>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">5. Your Choices</h2>
            <p>You can review, update, or delete your account information by accessing your account settings within the App.</p>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">6. Children's Privacy</h2>
            <p>The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children.</p>
        </div>

        <div class="mb-6">
            <h2 class="text-xl font-bold mb-2">7. Changes to this Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted in the App, and the revised policy will be effective immediately.</p>
        </div>

        

        <p class="mb-4">By using the İş Elanları mobile application, you consent to the practices described in this Privacy Policy.</p>


    </div>
        </div>
      </main>
    </>
  )
}