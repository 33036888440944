import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from "../authContext";
import axios from "axios";
import { Link } from "react-router-dom";


const Dashboard = () => {
  const userTokenLimit = 5000; // Example user token limit
  let usedTokens = 0; // Example used tokens
  const { user: loggedUser } = useAuthState();
  const [chatbots, setChatbots] = useState([]);

  useEffect(() => {
    
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    };

    // Fetch chatbots data from the /chatbots endpoint with the Authorization header
    axios.get("https://hirebot.co/api/v1/chatbots", { headers })
      .then(response => {
        setChatbots(response.data);
      })
      .catch(error => {
        console.error("Error fetching chatbots:", error);
      });
    
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://cdn.whelp.co/Screenshot_(534)_85d70e9b-4c4c-4ebe-b881-1715fc65f8a3.png?cdn_key=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEwMiwiaWF0IjoxNzA2NzkxMTAxLCJuYmYiOjE3MDY3OTExMDEsImp0aSI6IjI0MGI0MTRmLTlhYjItNDY1OS05YmNjLTAwZWI3NzlmMmQ4YSIsImV4cCI6MTcwNjg3NzUwMSwidHlwZSI6ImFjY2VzcyIsImZyZXNoIjpmYWxzZSwiaWQiOjEwMiwiZGF0YSI6eyJtYW5hZ2VyX2lkIjoxMDIsImNvbXBhbnlfaWQiOjIsInBhcnRuZXJfaWQiOjAsInZlcmlmeSI6bnVsbH19.EqZitCdYWdeXpdxba0dZNlvdM-vRCTZjBmH-j1BD59M',
        headers: { 
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEwMiwiaWF0IjoxNzA2MTEzNzI5LCJuYmYiOjE3MDYxMTM3MjksImp0aSI6IjZkZjU2YmEwLTk3NjktNDhiZC1hNTVjLWU3NGRmZjMyODY3OSIsImV4cCI6MTcwNjIwMDEyOSwidHlwZSI6ImFjY2VzcyIsImZyZXNoIjpmYWxzZSwiaWQiOjEwMiwiZGF0YSI6eyJtYW5hZ2VyX2lkIjoxMDIsImNvbXBhbnlfaWQiOjIsInBhcnRuZXJfaWQiOjAsInZlcmlmeSI6bnVsbH19.eSeKGgYwTHe9P3HSWwJoHDm3JpHwoVxRrDFDYdi8h-A', 
        }
      };
      
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
      
  }, []);
  const isAuth = localStorage.getItem('access_token');

  if (!isAuth) {
    return <Navigate to="/" replace />;
  }
  
  console.log("Logged");
  if (loggedUser) {
    usedTokens = loggedUser.used_tokens
  }
  const tokenPercentage = (usedTokens / userTokenLimit) * 100;

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-3/4 mx-auto pt-4">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="bg-gray-100 p-4 rounded-md shadow-md">
            <h2 className="text-lg font-semibold mb-2">User Token Limit</h2>
            <div className="flex items-center space-x-4">
              <div className="w-1/2">
                <div className="text-lg font-semibold">
                  {usedTokens} / {userTokenLimit}
                </div>
                <div className="mt-2">
                  <div className="h-2 bg-gray-300 rounded">
                    <div
                      className="h-2 bg-blue-500 rounded"
                      style={{ width: `${tokenPercentage}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">
                  Upgrade Limit
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-lg font-semibold mb-2">Chatbots</h2>
            <ul className="space-y-2">
            {chatbots.map(chatbot => (
              
              <Link key={chatbot.id} to={`/app/chat/${chatbot.slug}`}>
              <li key={chatbot.id}>
                  <h3 className="text-xl font-semibold text-gray-600">{chatbot.name}</h3>
                  {/* <p>{project.description}</p> */}
                </li>
               </Link>
            ))}
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
