import React, { useState, useEffect } from 'react';

import { Outlet } from "react-router-dom";
import HeaderNav from '../components/header'
import Footer from '../components/footer';
import { useAuthState, useAuthDispatch, fetchUserData } from "../authContext";


const Layout = () => {
  const dispatch = useAuthDispatch();
    const { user: loggedUser, status, error } = useAuthState();

    useEffect(() => {
      // Call the fetchUserData function when the component mounts
      fetchUserData(dispatch);
    }, [dispatch]);

    function isDarkMode() {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          return true;
        }
        return false;
      }
      
      // Function to update favicon based on the dark mode
      function updateFavicon() {
        const faviconElement = document.getElementById('favicon');
        const faviconPath = isDarkMode() ? 'https://storage.googleapis.com/hirebot-storage/static/fav-dark.png' : 'https://equations.fra1.cdn.digitaloceanspaces.com/resume/dark.png';
        faviconElement.setAttribute('href', faviconPath);
      }
      
      // Call the function when the page loads
      updateFavicon();
      
      // You can also listen for changes in the color scheme and update the favicon accordingly
      window.matchMedia('(prefers-color-scheme: dark)').addListener(updateFavicon);

  return (
    <>
      <HeaderNav/>
        <Outlet />
      <Footer/>
    </>
  )
};

export default Layout;
