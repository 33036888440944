import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login';
import Layout from './pages/layout';
import LandingPage from './pages/landingPage';
import SignUp from './pages/signUp';
import Dashboard from './pages/dashboard';
import { AuthProvider } from './authContext';
import {appHistory} from "./history";
import AppLayout from './pages/appLayout';
import NewBot from './pages/app/newBot';
import ChatbotDetail from './pages/app/chatbot';
import PrivacyPage from './pages/Privacy';
import ComingSoon from "./pages/comingSoon";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <BrowserRouter history={appHistory}>
      <Routes>
        <Route path="/">
          <Route index element={< ComingSoon />} />
          <Route path="privacy" element={<PrivacyPage />} />
        </Route>
        <Route path="/landing" element={<Layout />}>
          <Route index element={< LandingPage />} />
          <Route index element={< ComingSoon />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
        </Route>
        <Route path='/app' element={<AppLayout />}>
          <Route index element={< Dashboard />} />
          <Route path="new" element={<NewBot />} />
          <Route path="chat/:slug" element={<ChatbotDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
